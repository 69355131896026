import "../css/style.scss"

// Our modules / classes
// import MobileMenu from "./modules/MobileMenu"

// Instantiate a new object using our modules/classes
// const mobileMenu = new MobileMenu()

import ImagesLoaded from "imagesloaded"
import Lazyload from "lazyload"
import Swiper from "swiper/bundle"
// import Swiper, { Navigation } from "swiper/bundle"

// Our modules / classes
import LoadMorePosts from "./modules/LoadMorePosts"
let loadMorePosts

var debug = true
const mobileBreakpoint = 782
let mobileView = false

const ScrollDelta = 10 // how many pixels to scroll to call scroll dependent function

let adminBarHeight = 0
let siteTitleHeight = 0
let titleHeight = 0
let navHeight = 0

let historyUpdate = false // to push to history or not
let autoplayOn = true
let autoplaySpeed = 800
let autoplayDelay = 3000
let orientationLandscape = true
let shuffleInterval
let startAutoShuffleTimeout

let lastScroll = 0
let prevScrollDelta = 0
let scrollYPositions = {}

let currentContentID = -1
let prevContentID = -1
let navOpen = false // initially closed
let navChanging = false
let navChangingTimeout = null
const navChangingTime = 400
let autoScrolling = false

let requestAllPartsInterval = null
let nowWithKeyboard = false // if true mobile keyboard is showing
let observer

jQuery(function ($) {
  // if (debug) console.log("document ready!")

  if (checkFlexGap()) {
    document.documentElement.classList.add("flexbox-gap")
  } else {
    document.documentElement.classList.add("no-flexbox-gap")
  }

  // console.log("nonce", localized.nonce, localized.nonce_field);
  if ("scrollRestoration" in history) {
    history.scrollRestoration = "manual"
  }
  // for ios touch -> enable active state for links
  document.addEventListener(
    "touchstart",
    function () {
      // console.log("touchstart")
    },
    false
  )

  // const DateTime = luxon.DateTime;

  let pathname = window.location.pathname
  // console.log('pathname', pathname);
  // console.log('history state', history.state);

  const blogName = document.querySelector('meta[name="name"]').content
  const blogDescription = document.querySelector('meta[name="description"]').content

  // console.log(blogName, blogDescription);

  // -----------------------------------------------
  // const openClose = $(".site-header__openclose")

  const siteHeader = $(".site-header")
  const navContainer = $(".site-menu")
  const imagesContainer = $(".images-container")

  const siteTitle = $(".site-header__title")
  const siteTitleLink = $(".site-header__link")
  const menuNavLinks = $(".menu-main > li.menu-item > a")
  const menuNavSubLinks = $(".sub-menu > li.menu-item > a")
  const imprintTitle = $(".imprint-title")

  const titleContainer = $(".title-container")
  const arrowDownContainer = $(".arrow-down-container")
  const arrowDown = arrowDownContainer.find(".arrow-down")
  const contentContainer = $(".content-container")

  // -----------------------------------------------------------------------------------------------------
  const $html = $("html")
  const $body = $("body")
  const $mainMenu = $(".menu-main")
  const openMenu = $(".site-menu__menu-item")
  const closeMenu = $(".site-menu__menu-close")
  const homeID = $body.data("home-id").toString() // post id of the home page as string
  const postsPageID = $body.data("postspage-id").toString()

  $html.addClass("js") // js is working

  setLandscape()
  touchSupport()

  // set highlight color from customizer
  document.documentElement.style.setProperty("--highlight-color", $("body").data("highlightcolor"))
  // set background color from customizer
  document.documentElement.style.setProperty("--bgcolor", $("body").data("bgcolor"))

  calcVhPropertyFromClientHeight() // for mobile viewport height on ios
  setAdminBarHeight()
  navAndTitleHeight(true)
  checkMobileView()

  // hide and fade in nav and content elements with visibility hidden
  navContainer.hide()
  navContainer.css("visibility", "visible")
  navContainer.fadeIn(400)

  contentContainer.hide()
  contentContainer.css("visibility", "visible")
  contentContainer.fadeIn()

  setTimeout(() => {
    // safety
    navAndTitleHeight(true)
  }, 100)

  const postsContainer = $(".posts-container")
  let postItemContainers = $(".post-item-container")

  postItemContainers.hide()

  const videoContainer = $(".intro-video-container")
  if (videoContainer.length) {
    const video = videoContainer.children("video")
    contentContainer.hide()
    // navContainer.hide()

    if (video.length) {
      // console.log(video[0])

      // poll the video duration (if not ready -> duration == NaN)
      let i = setInterval(function () {
        if (video[0].readyState > 0) {
          const miliseconds = parseFloat(video[0].duration) * 1000
          // console.log("video length millis", miliseconds)
          clearInterval(i)
          setTimeout(() => {
            if (videoContainer.is(":visible")) {
              videoContainer.fadeOut()
              // navContainer.fadeIn()
              contentContainer.fadeIn()
            }
          }, miliseconds)
        }
      }, 200)

      video.on("ended", () => {
        videoContainer.fadeOut()
        // navContainer.fadeIn()
        contentContainer.fadeIn()
        clearTimeout(timeout)
      })
    }

    // safety video container hide/fade out
    let timeout = setTimeout(() => {
      if (videoContainer.is(":visible")) {
        videoContainer.fadeOut()
        // navContainer.fadeIn()
        contentContainer.fadeIn()
      } else {
      }
    }, 10000)
  }

  // content retrival and display -----------------------------------------------------------------------------------

  // menu and title navigation ------------------------------------------------------------------------------------------------
  menuNavLinks.add(siteTitleLink).on("click", function (e) {
    // menuNavLinks.on('click', function (e) {

    // only if it is a page menu item
    const isPage = $(this).parent().hasClass("menu-item-object-page")
    const isProject = $(this).parent().hasClass("menu-item-object-project")

    if (isPage || isProject) {
      // links in the menu are either pages or project archive
      e.preventDefault()
      // if (mobileView) closeNav(true);

      const slug = $(this).attr("href").split(`${window.location.origin}/`)[1]

      // history only gets updated when clicking on a different menu item, and not when navigating by url (gotolocation)
      const ref = `${window.location.origin}/${slug}`

      if (window.location.href !== ref) {
        window.history.pushState(null, null, ref)
        // console.log("updated history ", ref);

        // update the title tag when menu click. page load sets the title tag automatically (theme support 'title-tag')
        if (slug !== "") {
          // not home -> page | blogname
          document.title = $(this).text()?.toUpperCase() + " | " + blogName
        } else {
          // is home -> blogname | blogdescription
          document.title = blogName + " | " + blogDescription
        }
      }
      if (isPage) openPageLink($(this))
      else if (isProject) openProjectWithSlug(`/${slug}`)
    }
  })

  // --------------------------------------------------------------------------------------------------------------------------------------------------------
  function openPageLink($link) {
    // console.log("open page link", $link.attr("href"))

    const parent = $link.parent()

    // get page id from menu item classlist (wpse-object-id-XX)
    let classlist = parent.attr("class").split(/\s+/)
    let pageID
    $.each(classlist, function (index, item) {
      if (item.startsWith("wpse-object-id-")) {
        const splits = item.split("wpse-object-id-")
        if (splits.length > 1) pageID = splits[1]
        return false
      }
    })

    $("li.menu-item").removeClass("current-menu-item")
    parent.addClass("current-menu-item")

    openPageWithID(pageID)
  }

  function openPageWithID(pageID) {
    // console.log("open page with id", pageID)
    if (pageID) {
      // closeNav()
      stopAllVideos()
      // hide all other page and post item content and the images
      if (pageID !== currentContentID) {
        $(".post-item-container").not(`#${pageID}`).hide() // hide page content
        $(".images-container__post-item-images").not(`#${pageID}-images`).fadeOut() // hide page images container
        titleContainer.children(".post-item-title").not(`#${pageID}-title`).hide() // hide page title
      }
      // make site header / nav container half width (if not home page)
      if (pageID === homeID) {
        siteHeader.removeClass("site-header--half-width") // site header full width
        navContainer.addClass("site-menu--full-width") // nav full width
        navContainer.removeClass("site-menu--half-width") // nav full width
        imagesContainer.removeClass("images-container--half-width") // images full width
        imagesContainer.show()
        arrowDownContainer.addClass("arrow-down-container--no-show")
        contentContainer.removeClass("content-container--half-width") // content container has 0 width for home page
        contentContainer.removeClass("content-container--full-width") // full width class for news page only
      } else if (pageID === postsPageID) {
        // news page has full width content
        siteHeader.addClass("site-header--half-width") // still half width
        navContainer.removeClass("site-menu--full-width") // still half width
        imagesContainer.hide()
        arrowDownContainer.addClass("arrow-down-container--no-show")
        contentContainer.removeClass("content-container--half-width")
        contentContainer.addClass("content-container--full-width") // full width class for news page only
      } else {
        // all other pages are half width
        siteHeader.addClass("site-header--half-width")
        navContainer.addClass("site-menu--half-width")
        navContainer.removeClass("site-menu--full-width")
        imagesContainer.addClass("images-container--half-width")
        imagesContainer.show()
        arrowDownContainer.removeClass("arrow-down-container--no-show")
        contentContainer.addClass("content-container--half-width")
        contentContainer.removeClass("content-container--full-width")
      }
      // remove the class for when a project slider is fullscreen (shows the x/ close button)
      navContainer.removeClass("site-menu--project-slider-full-width")

      setCurrentContentID(pageID)

      if ($(`div#${pageID}`).length == 0) {
        // post-item-container for this page not yet existing -> get the page
        // closeNav()
        // console.log("page not yet..", pageID, homeID === pageID ? "is home page" : "is page")
        // page container does not exist yet -> load page with page id

        // get page content
        $.getJSON(localized.root_url + "/wp-json/wp/v2/pages/" + pageID, page => {
          // console.log(page)

          // separate title container for mobile view
          titleContainer.append(`
            <h1 id="${pageID}-title" class="post-item-title">${page.title.rendered}</h1>  
          `)
          const newTitle = titleContainer.children(`#${pageID}-title`)
          newTitle.hide()

          // add page content ( no content for home page )
          postsContainer.append(`
            <div id="${pageID}" class="post-item-container post-type-page ${pageID === homeID ? "page-home " : ""}initialized" 
            data-title="${page.title.rendered}" data-history="${page.slug}" 
            data-background-color="${page.page_background_color}" data-color="${page.page_text_color}">

            ${
              pageID !== homeID // home page has no content
                ? `                                  
                <h1 class="post-item-title">${page.title.rendered}</h1>
                ${pageID === postsPageID ? `<div class="post-item-close"><div></div></div>` : ""}
                <div class="post-item-content">    
                    ${page.content.rendered}
                </div>
                
              ${
                page.logo_image || page.logo_text || page.credits
                  ? `
              
              <div class="post-item-page-credits">
              ${
                page.logo_image
                  ? `<div class="logo-image-container">
                <img data-id="${page.logo_image.id}" src="${page.logo_image.src}"
                srcset="${page.logo_image.srcset}" sizes="100px" alt="${page.logo_image.alt}">
                </div>`
                  : ""
              }
              ${page.logo_text ? `<div class="logo-text">${page.logo_text}</div>` : ""}
              ${page.credits ? `<div class="imprint">${page.credits}</div>` : ""}
              </div>
              `
                  : ``
              }
              
                `
                : ``
            }
            </div>
          `)

          const newPage = postsContainer.children(`#${pageID}`)
          newPage.hide()
          initPage(newPage) // get color data
          if (pageID === postsPageID) {
            newPage.children(".post-item-close").on("click", closePage) // add close news page handler
            // add load more posts functionality -> create LoadMorePosts object with news page id
            loadMorePosts = new LoadMorePosts(postsPageID)
            loadMorePosts.loadMorePosts()
          }
          // observe page content visibility for arrow down visibility. not on news and home page
          if (pageID !== homeID && pageID !== postsPageID) observeElement(newPage)

          newPage.imagesLoaded(function () {
            addLazyloadToElement(newPage)
            newPage.fadeIn()
            if (pageID !== homeID) newTitle.fadeIn()
          })

          // get featured image if not home and not news page
          if (page.featImage && pageID !== homeID && pageID !== postsPageID) {
            imagesContainer.append(`
            <div id="${pageID}-images" class="images-container__post-item-images">
              <div class="page-image">
                <img src="${page.featImage.url}" srcset="${page.featImage.srcset}" sizes="100vw">
              </div>              
            </div> 
          `)
          } else if (page.featured && page.featured.type == "Pictures") {
            // home page featured images max 2 pics
            imagesContainer.append(`
            <div id="${pageID}-images" class="images-container__post-item-images">
              ${page.featured.data
                .map(
                  img =>
                    `
                <div class="home-image">
                  <img src="${img.src}" srcset="${img.srcset}" sizes="${img.sizes}" alt="${img.alt}">
                </div>              
                `
                )
                .join("")}
            </div> 
          `)
          } else if (page.featured && page.featured.type == "Project") {
            imagesContainer.append(`
            <div id="${pageID}-images" class="images-container__post-item-images">
              ${page.featured.data
                .map(
                  project =>
                    `
                <a class="home-project" href="${project.link}" data-id="${project.id}" data-title="${project.title}">
                  <img data-id="${project.featured_media}" src="${project.src}" srcset="${project.srcset}" sizes="100vw" alt="${project.alt}">               
                  <div class="home-project__title-container" style="color:${project.color}">
                    <div class="home-project__title">${project.title}</div>
                    <div class="home-project__subtitle">${project.subtitle}</div>
                  </div>     
                </a>         
                `
                )
                .join("")}
            </div> 
          `)
          }
          //

          const newImg = imagesContainer.children(`#${pageID}-images`)
          if (newImg.length) {
            newImg.hide()

            newImg.imagesLoaded(function () {
              newImg.fadeIn()
            })
          }

          if (mobileView) {
            closeNav(navChangingTime, titleContainer)
          } else {
            closeNav(navChangingTime)
            scrollTop()
          }
        })
      } else {
        // --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        // page container already exists -> show the page
        // - either loaded by php page template, then we have to initialize the content first
        // - or already loaded before by ajax and is initialized
        // console.log("page exists", pageID)
        closeNav()

        const title = $(`#${pageID}-title`)
        title.hide()
        const page = $(`#${pageID}`)
        page.hide()
        const pageImages = $(`#${pageID}-images`)
        pageImages.hide()

        if (!page.hasClass("initialized")) {
          // console.log("init existing page")
          // page not yet initialized because it was loaded by php
          closeNav(navChangingTime) // on init close nav menu fast

          if (pageID === homeID) {
            // console.log("is home!!!")
            // is page home
            page.imagesLoaded(function () {
              page.fadeIn()
              // no title fade in
            })
          } else {
            // all other pages
            initPage(page) // get colors
            page.imagesLoaded(function () {
              // console.log("page images loaded")
              page.fadeIn()
              title.fadeIn()
            })

            if (mobileView) {
              closeNav(navChangingTime, titleContainer)
            } else {
              closeNav(navChangingTime)
              scrollTop()
            }
          }

          pageImages.imagesLoaded(function () {
            // console.log("images loaded in images container")
            pageImages.fadeIn()
          })

          page.addClass("initialized")
        } else {
          // page content existing and already initialized -> previously viewed
          // console.log("page already inited")
          page.fadeIn()
          pageImages.fadeIn()
          if (pageID !== homeID) {
            title.fadeIn()

            // testest
            if (pageID === postsPageID) restoreScrollPosition()
            else {
              if (mobileView) {
                closeNav(navChangingTime, titleContainer)
              } else {
                closeNav(navChangingTime)
                scrollTop()
              }
            }
          }
        }
        observeElement(page) // observe current page content for down arrow on mobile
      }
    }
  }

  function closeContent() {
    if (prevContentID !== -1) {
      // previous content exist -> browser back
      history.back()
    } else {
      // no previous content yet -> show home page
      siteTitleLink.trigger("click")
    }
  }

  function closePage() {
    // if (prevContentID !== -1) {
    //   // previous content exist -> browser back
    //   history.back()
    // } else {
    //   // no previous content yet -> show home page
    siteTitleLink.trigger("click")
    // }
  }

  // handler for "project links" on project archive site AND the "project" on the home page, if set.
  $(document).on("click", ".post-item-content__project, .home-project", function (e) {
    e.preventDefault()
    // history only gets updated when clicking on a different menu item, and not when navigating by url (gotolocation)
    const ref = $(this).attr("href")

    if (window.location.href !== ref) {
      window.history.pushState(null, null, ref)
      // console.log("updated history ", ref)

      // update the title tag when menu click. page load sets the title tag automatically (theme support 'title-tag')
      document.title = $(this).data("title")?.toUpperCase() + " | " + blogName
    }

    const id = $(this).data("id")
    openProjectWithID(id)
  })

  // show "ALL POSTS" button handler in single post view
  $(document).on("click", ".post-type-post .post-item-show-all", function (e) {
    stopAllVideos()
    // history only gets updated when clicking on a different menu item, and not when navigating by url (gotolocation)
    const ref = `${window.location.origin}/news/`

    if (window.location.href !== ref) {
      window.history.pushState(null, null, ref)
      // console.log("updated history ", ref)

      // update the title tag when menu click. page load sets the title tag automatically (theme support 'title-tag')
      document.title = "NEWS | " + blogName
    }
    gotoLocation(ref)
  })

  // show "ALL PROJECTS" button handler in a single project view
  $(document).on("click", ".post-type-project .post-item-show-all", function (e) {
    stopAllVideos()
    // console.log("show all projects", $(this).text())
    // if single project loaded by page load (php), then no previous page. -> goto project archive page
    // else previous page must be project archive page -> history back

    // if (prevContentID !== -1) {
    //   // previous content exist -> browser back . must be project archive page
    //   history.back()
    // } else {
    // no previous content yet -> show project archive page

    // history only gets updated when clicking on a different menu item, and not when navigating by url (gotolocation)
    const ref = `${window.location.origin}/projects/`

    if (window.location.href !== ref) {
      window.history.pushState(null, null, ref)
      // console.log("updated history ", ref)

      // update the title tag when menu click. page load sets the title tag automatically (theme support 'title-tag')
      document.title = "PROJECTS | " + blogName
    }
    openProjectArchive()
    // }
  })

  function openProjectWithSlug(slug) {
    // console.log("open project slug", slug)

    // check if single project or archive and get the id
    $.getJSON(`${localized.root_url}/wp-json/pathtopost/v1/get?path=${slug}`, post => {
      // console.log(post)
      if (post.ID > 0) {
        // project found
        openProjectWithID(post.ID)
      } else {
        // is not a single project
        openProjectArchive()
      }
    })
  }

  function openProjectWithID(projectID) {
    if (projectID) {
      // console.log("open project with id", projectID)

      closeNav()

      // hide all other page and post items
      $(".post-item-container").not(`#${projectID}`).hide()
      $(".images-container__post-item-images").not(`#${projectID}-images`).fadeOut() // hide page images container
      titleContainer.children(".post-item-title").not(`#${projectID}-title`).hide() // hide page title

      siteHeader.addClass("site-header--half-width")
      navContainer.addClass("site-menu--half-width")
      navContainer.removeClass("site-menu--full-width")
      imagesContainer.addClass("images-container--half-width")
      contentContainer.addClass("content-container--half-width")
      contentContainer.removeClass("content-container--full-width")

      imagesContainer.show()
      arrowDownContainer.removeClass("arrow-down-container--no-show")

      setCurrentContentID(projectID)

      if ($(`div#${projectID}`).length == 0) {
        // post container does not exist yet -> load post with post id

        // console.log("load project", projectID)

        $.getJSON(localized.root_url + "/wp-json/wp/v2/project/" + projectID, project => {
          // console.log(project)

          titleContainer.append(`
          <h1 id="${projectID}-title" class="post-item-title">${project.title.rendered}</h1>  
        `)

          // separate title for mobile view
          const newProjectTitle = titleContainer.children(`#${projectID}-title`)
          newProjectTitle.hide()

          postsContainer.append(`
                            <div id="${projectID}" class="post-item-container post-type-project" data-title="${project.title.rendered}" data-history="${project.slug}">
                                                            
                                <h1 class="post-item-title">${project.title.rendered}</h1>                                                                  
                                <div class="post-item-content">
                                    ${project.content.rendered}
                                </div>
                                <div class="post-item-project-credits">
                                  ${project?.credits
                                    ?.map(
                                      credit => `
                                      <div class="post-item-project-credits__item">
                                        <div>${credit.what}</div>
                                        <div>${credit.who}</div>
                                      </div>
                                      `
                                    )
                                    .join("")}
                                </div>
                                <div class="post-item-show-all"><div>ALL PROJECTS</div></div>
                            </div>
                        `)

          // hide the new project post and fade it in when loaded
          const newProject = postsContainer.children(`#${projectID}`)
          newProject.children(".post-item-close").on("click", closeProject)
          newProject.hide()
          // observe project content visibility for arrow down visibility
          observeElement(newProject)

          newProject.imagesLoaded(function () {
            addLazyloadToElement(newProject)
            newProject.fadeIn()
            newProjectTitle.fadeIn()
          })

          // load project slide show and init slider
          imagesContainer.append(`
            <div id="${projectID}-images" class="images-container__post-item-images">
              <div class="swiper-container ${project.pictures.length <= 1 ? "only-one-slide" : ""}">
                <div class="swiper-wrapper">
                  ${project.pictures
                    .map(
                      slide => `
                    <div class="swiper-slide ${slide.select == "Picture" ? "image-slide" : ""}" ${slide.select == "Videofile" ? `style="{padding-top:${(slide.height / slide.width) * 50}%, padding-bottom:${(slide.height / slide.width) * 50}%}"` : ""}>
                      ${slide.select == "Picture" ? `<img srcset="${slide.srcset}" sizes="(min-width: 782px) 50vw, 100vw" src="${slide.src}" alt="${slide.alt}" class="swiper-lazy"/>` : `${slide.select == "Videolink" ? slide.iframe.replace("?feature=oembed", "?enablejsapi=1") : `${slide.select == "Videofile" ? `<video controls src="${slide.url}" type="${slide.mime_type}" width="${slide.width}" height="${slide.height}"></video>` : ""}`}`}
                      ${slide.select == "Video" ? `<div class="lightbox-open-button"></div>` : ""}
                    </div>
                    `
                    )
                    .join("")}
                </div>
                ${project.pictures.length > 1 ? `<div class="swiper-button-prev"></div><div class="swiper-button-next"></div>` : ""}
              </div>              
            </div> 
          `)

          // hide the new image container and fade it in
          const newProjectImages = imagesContainer.children(`#${projectID}-images`)
          if (newProjectImages.length) {
            newProjectImages.hide()

            newProjectImages.imagesLoaded(function () {
              addLazyloadToElement(newProjectImages)
              newProjectImages.fadeIn()
              const newSwiper = newProjectImages.children(`.swiper-container:not(.only-one-slide)`)
              createSwiper(false, newSwiper)

              newProjectTitle.fadeIn()
              if (mobileView) {
                closeNav(navChangingTime, titleContainer)
              } else {
                closeNav(navChangingTime)
                scrollTop()
              }
            })
          }
        })
      } else {
        // post container already exists -> show the post
        // console.log("project exists -> fade in")
        const title = titleContainer.children(`#${projectID}-title`)
        const project = $(`#${projectID}`)
        project.hide()
        const projectImages = $(`#${projectID}-images`)
        projectImages.hide()

        if (!project.hasClass("initialized")) {
          // console.log("init project")
          // observe project content visibility for arrow down visibility
          observeElement(project)

          project.children(".post-item-close").on("click", closeProject) // add close project handler

          projectImages.imagesLoaded(function () {
            addLazyloadToElement(projectImages)
            projectImages.fadeIn()
            const newSwiper = projectImages.children(`.swiper-container:not(.only-one-slide)`)
            createSwiper(false, newSwiper)
          })
          project.imagesLoaded(function () {
            project.fadeIn()
            title.fadeIn()
          })
          project.addClass("initialized")
        } else {
          // is initialized
          projectImages.fadeIn()
          project.fadeIn()
          title.fadeIn()
        }
        if (mobileView) {
          // scroll to project title
          setTimeout(() => {
            scrollToElement(titleContainer)
          }, 800)
        }
      }
    }
  }

  function closeProject() {
    // $(`#${projectID}`).hide()
    if (prevContentID !== -1) {
      // previous content exist -> browser back
      history.back()
    } else {
      // no previous content yet -> show home page
      siteTitleLink.trigger("click")
    }
  }

  // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  // slider img click -> make project slider fullscreen
  $(document).on("click", ".swiper-slide img", function (e) {
    enterSlideshowFullscreen()
  })

  //  Escape -> exit slider fullscreen
  window.addEventListener("keydown", e => {
    if (e.code === "Escape" && navContainer.hasClass("site-menu--project-slider-full-width")) {
      exitSlideshowFullscreen()
    }
  })

  function enterSlideshowFullscreen() {
    if (!navContainer.hasClass("site-menu--project-slider-full-width")) {
      contentContainer.removeClass("content-container--half-width")
      navContainer.addClass("site-menu--project-slider-full-width")

      siteHeader.children(".post-item-title").remove() // remove any project titles
      // copy project title element
      const projTitle = $(`#${currentContentID}`).children(".post-item-title").clone()

      // fade out site title and show the project title instead
      siteTitle.fadeOut(() => {
        siteHeader.append(projTitle)
        siteHeader.hide()
        siteHeader.fadeIn()
      })
    }
  }

  function exitSlideshowFullscreen() {
    contentContainer.addClass("content-container--half-width")
    navContainer.removeClass("site-menu--project-slider-full-width")
    const projTitle = siteHeader.children(".post-item-title")
    projTitle.fadeOut(() => {
      projTitle.remove()
      siteTitle.fadeIn()
    })
  }

  // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  function openProjectArchive() {
    // console.log("open the projects archive")
    closeNav()
    stopAllVideos()
    // hide all other page and post items
    let projectArchive = $("#project-archive")
    $(".post-item-container").not(projectArchive).hide()
    titleContainer.children(".post-item-title").not(`#project-archive-title`).hide() // hide other page title
    imagesContainer.hide()
    arrowDownContainer.addClass("arrow-down-container--no-show")

    siteHeader.addClass("site-header--half-width")
    // remove the class for when a project slider is fullscreen (shows the x/ close button)
    navContainer.removeClass("site-menu--project-slider-full-width")
    navContainer.addClass("site-menu--half-width")

    setCurrentContentID("project-archive")

    if (projectArchive.length == 0) {
      // no archive yet
      // console.log("no project archive yet")
      $.getJSON(localized.root_url + "/wp-json/wp/v2/project/", projects => {
        // console.log(projects)

        // separate title container for mobile view
        titleContainer.append(`
         <h1 id="project-archive-title" class="post-item-title">PROJECTS</h1>  
       `)
        const newTitle = titleContainer.children(`#project-archive-title`)
        newTitle.hide()

        postsContainer.append(`
                          <div id="project-archive" class="post-item-container post-type-archive initialized" data-title="Projects" data-history="projects">

                              <h1 class="post-item-title">PROJECTS</h1>
                              <div class="post-item-close"><div></div></div>
                              <div class="post-item-content post-item-content--grid">
                                ${projects
                                  .map(
                                    project => `
                                      <a class="post-item-content__project" href="${localized.root_url + "/projects/" + project.slug}" data-id="${project.id}" data-slug="${project.slug}" data-title="${project.title.rendered}">
                                        <div class="post-item-content__project-featured-image">
                                          <img class="lazyload" data-src="${project.featImage.url}" data-srcset="${project.featImage.srcset}" sizes="(max-width: 782px) 100vw, 50vw" alt="${project.featImage.alt}">
                                        </div> 
                                        <div class="post-item-content__project-title">${project.title.rendered}</div>
                                      </a>`
                                  )
                                  .join("")}
                              </div>
                          </div>
                      `)

        projectArchive = $("#project-archive")
        projectArchive.children(".post-item-close").on("click", closeProjectArchive)
        projectArchive.hide()
        projectArchive.imagesLoaded(function () {
          addLazyloadToElement(projectArchive)
          contentContainer.addClass("content-container--full-width")
          newTitle.fadeIn()
          projectArchive.fadeIn()
          if (mobileView) {
            // scroll to project title
            setTimeout(() => {
              scrollToElement(titleContainer)
            }, 800)
          }
        })
      })
    } else {
      // console.log("project archive exists")
      const title = titleContainer.children(`#project-archive-title`)
      if (!projectArchive.hasClass("initialized")) {
        // console.log("init the project")
        projectArchive.children(".post-item-close").on("click", closeProjectArchive)
        projectArchive.addClass("initialized")
        projectArchive.imagesLoaded(function () {
          addLazyloadToElement(projectArchive)
          contentContainer.addClass("content-container--full-width")
          projectArchive.fadeIn()
          title.fadeIn()
        })
      } else {
        contentContainer.addClass("content-container--full-width")
        projectArchive.fadeIn()
        title.fadeIn()
      }
      if (mobileView) {
        // scroll to project title
        setTimeout(() => {
          scrollToElement(titleContainer)
        }, 800)
      }
    }
  }

  function closeProjectArchive() {
    siteTitleLink.trigger("click") // to home page
  }

  function scrollToElement($this, time) {
    // console.log("scroll to element", $this.offset().top)
    time = time ?? navChangingTime
    autoScrolling = true
    $("html, body").animate(
      {
        scrollTop: $this.offset().top - adminBarHeight
      },
      time,
      function () {
        autoScrolling = false
      }
    )
  }

  function scrollTop(top, time) {
    top = top || 0
    time = time ?? navChangingTime
    autoScrolling = true
    // console.log("scrolltop", top)
    $("html, body")
      .add(contentContainer)
      .animate(
        {
          scrollTop: top
        },
        time,
        function () {
          autoScrolling = false
        }
      )
  }

  function restoreScrollPosition() {
    const top = scrollYPositions[currentContentID] ?? 0
    scrollTop(top, 1)
  }

  function initPage(page) {
    const bgcolor = page.data("background-color")
    // console.log("page bgcolor is", bgcolor)
    page.css("background-color", bgcolor)

    const color = page.data("color")
    // console.log("page color is", color, "!")
    page.css("color", color)
  }

  function addLazyloadToElement($elem) {
    // https://github.com/tuupola/lazyload
    // $("img.lazyload").lazyload();
    // lazyload();

    // const elem = document.querySelector(`[id="${elemID}"]`);
    const elem = $elem[0]
    const images = elem.querySelectorAll("img.lazyload")
    // console.log("lazyload images", images, " in container ", $elem);

    new Lazyload(images, {
      root: null,
      rootMargin: "50px",
      threshold: 0
    })

    // add load event listener for lazyload images
    $elem.find(`img.lazyload`).on("load", function () {
      // console.log("img on elem xx loaded", $elem.attr('id'));
      $(this).addClass("loaded") // add loaded class to image -> fade in with css opacity 1
    })
  }

  // click on arrow button -> scroll to page / post content
  arrowDown.on("click", function () {
    // $([document.documentElement, document.body]).animate(
    $("html, body").animate(
      {
        scrollTop: contentContainer.offset().top
      },
      navChangingTime
    )
  })

  // add click handler for news item on news page
  $(document).on("click", ".news-item", function (e) {
    e.preventDefault()
    // console.log(window.location);
    const ref = $(this).attr("href")
    // console.log("open post", ref)

    if (window.location.href !== ref) {
      window.history.pushState(null, null, ref)
      // console.log("updated history ", ref)
      // update the title tag when menu click. page load set the title tag automatically (theme support 'title-tag')
      document.title = $(this).data("title")?.toUpperCase() + " | " + blogName
    }
    const postID = $(this).data("id")
    if (postID) {
      openPostWithID(postID)
    }
  })

  function openPostWithID(postID) {
    if (postID) {
      // console.log("open post with id", postID)

      closeNav(1)

      // hide all other page and post items
      $(".post-item-container").not(`#${postID}`).hide()
      // titleContainer.hide()
      imagesContainer.hide()
      arrowDownContainer.addClass("arrow-down-container--no-show")

      siteHeader.addClass("site-header--half-width")
      contentContainer.addClass("content-container--full-width")
      contentContainer.removeClass("content-container--half-width")
      setCurrentContentID(postID)

      if ($(`div#${postID}`).length == 0) {
        // post container does not exist yet -> load post with post id

        // console.log("load post", postID)

        $.getJSON(localized.root_url + "/wp-json/wp/v2/posts/" + postID, post => {
          // console.log(post)

          postsContainer.append(`
                            <div id="${postID}" class="post-item-container post-type-post initialized" data-title="${post.title.rendered}" data-history="${post.slug}">
                              <div class="post-item-show-all"><div>ALL NEWS</div></div>

                              <div class="post-item-date">${post.date_formated}</div>
                              <h1 class="post-item-title">${post.title.rendered}</h1>
                              <div class="post-item-content post-item-content--half-width">
                                  ${post.content.rendered}
                              </div>
                          </div>
                        `)

          const newPost = postsContainer.children(`#${postID}`)
          changeYTURL(newPost)
          newPost.hide()
          newPost.imagesLoaded(function () {
            newPost.fadeIn()
          })
        })
      } else {
        // post container already exists -> show the post
        // console.log("post exists -> fade in")
        const post = $(`#${postID}`)
        post.hide()
        if (!post.hasClass("initialized")) {
          changeYTURL(post)
          post.imagesLoaded(function () {
            post.fadeIn()
          })
          post.addClass("initialized")
        } else {
          post.fadeIn()
        }
      }
    }
  }

  function changeYTURL(post) {
    // change youtube url if any to be able to pause it
    post.find("iframe").each(function () {
      let src = $(this).attr("src")
      src = src.replace("?feature=oembed", "?enablejsapi=1")
      $(this).attr("src", src)
    })
  }

  // $(window).on('load', function () {
  //     console.log("load event");
  // });

  // close post click handler
  $(document).on("click", ".post-item-title__close", function (e) {
    siteTitleLink.trigger("click")
  })

  // openClose.on("click", function () {
  //   if (navOpen) {
  //     closeNav()
  //   } else {
  //     openNav()
  //   }
  // })

  openMenu.on("click", function () {
    openNav()
  })

  // close menu or exit fullscreen slideshow
  closeMenu.on("click", function () {
    if (navContainer.hasClass("site-menu--project-slider-full-width")) {
      exitSlideshowFullscreen()
    } else {
      closeNav()
    }
  })

  function openNav() {
    // console.log("open nav")
    // openClose.addClass("checked")
    $html.addClass("nav-open")
    navContainer.addClass("site-menu--nav-open")
    navContainer.removeClass("site-menu--half-width")
    navOpen = true
    navChanging = true
    let h = window.innerHeight - adminBarHeight - siteTitleHeight
    if (mobileView && navContainer.hasClass("site-menu--full-width")) {
      // nav goes fullscreen on the homepage
      h += siteTitleHeight
    }

    navContainer.animate(
      {
        height: h
      },
      400
    )

    clearTimeout(navChangingTimeout)
    navChangingTimeout = setTimeout(() => {
      navChanging = false
    }, navChangingTime)
    // navChanging = true
    // $(".site-header__menu").slideDown(() => {
    //   navChanging = false
    // })
  }

  function closeNav(duration, scrollToElem) {
    duration = duration ?? navChangingTime
    // console.log("close nav", navOpen, duration, scrollToElem)
    // openClose.removeClass("checked")

    // // remove classes may added by scrolling
    siteHeader.removeClass("site-header--visible")
    navContainer.removeClass("site-menu--visible")

    if (navOpen || scrollToElem) {
      if (!mobileView) {
        $html.addClass("nav-closing")
        navContainer.removeClass("site-menu--nav-open")
      }

      navContainer.animate(
        {
          height: navHeight
        },
        400
      )

      clearTimeout(navChangingTimeout)
      navChangingTimeout = setTimeout(() => {
        if (mobileView) {
          navContainer.removeClass("site-menu--nav-open")
        } else {
          $html.removeClass("nav-closing")
        }
        navChanging = false
        navOpen = false
        $html.removeClass("nav-open")
        // if (contentContainer.hasClass("content-container--half-width")) navContainer.addClass("site-menu--half-width")
        if (!navContainer.hasClass("site-menu--full-width")) navContainer.addClass("site-menu--half-width")

        if (scrollToElem) {
          scrollToElement(scrollToElem)
        } else if (mobileView) {
          // restore scroll position
          scrollTop(scrollYPositions[currentContentID], 0)
        }
      }, duration)
    }
  }

  // init swiper
  function createSwiper(autoheight, $swiper, slideIndex) {
    //    <!-- Initialize Swiper -->
    const options = {
      // ally: true,
      // modules: [Navigation],
      slidesPerView: 1,
      spaceBetween: 0,
      slidesPerGroup: 1,
      grabCursor: false,
      autoHeight: autoheight ? autoheight : false, //enable auto height
      // autoHeight: mobileView, //enable auto height
      loop: false,
      init: true,
      observer: true,
      observeParents: true,
      speed: 800,
      simulateTouch: true,
      initialSlide: slideIndex ? slideIndex : 0,
      // Disable preloading of all images
      // preloadImages: false,
      // Enable lazy loading
      // lazy: true,
      mousewheel: {
        invert: false,
        forceToAxis: true,
        sensitivity: 1
      },
      keyboard: {
        enabled: true
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      on: {
        init: function (swiper) {
          // console.log("swiper inited")
        },

        transitionStart: function (swiper) {
          // check slides and stop any video
          swiper.slides.each(function () {
            const yt = this.querySelector("iframe")
            if (yt) stopVideo(yt)
            const video = this.querySelector("video")
            if (video) stopVideo(video)
          })
        },

        transitionEnd: function (swiper) {}
      }
    }

    if (typeof $swiper !== "undefined") {
      //  create only one slider for jquery container

      const swiper = new Swiper($swiper[0], options) // pass in html element

      // console.log("created one swiper", swiper)

      // get aspect ration of iframe videos and set padding-bottom on the slide
      $swiper.find("iframe").each(function () {
        // $(this).attr("id", `player${ytID}`)
        // ytID++
        const w = $(this).attr("width")
        const h = $(this).attr("height")
        const padding = (h / w) * 50
        $(this)
          .parent()
          .css({ "padding-top": `${padding}%`, "padding-bottom": `${padding}%` })
      })
    } else {
      // recreate sliders for all existing containers

      // first destroy all sliders
      const initSliders = $(".swiper-container-initialized")
      // console.log("destroy sliders", initSliders.length);
      initSliders.each(function () {
        $(this)[0].swiper.destroy()
      })

      // following classes will be removed by destroy()
      // $('.swiper-container').removeClass("swiper-container-autoheight");
      $(".swiper-container").removeClass("swiper-container-pointer-events")
      $(".swiper-container").removeClass("swiper-container-android")

      // setTimeout(() => {
      new Swiper(".swiper-container:not(.only-one-slide)", options) // pass in css selector string
      // console.log("swiper all. new slider nbr", $('.swiper-container:not(.only-one-slide)').length);
      // }, 200);
    }
  }

  // handle pathname ------------------------------------------------------------------------------------------------
  gotoLocation(pathname)

  function gotoLocation(pathname) {
    // console.log("goto to location:", pathname)

    // get post info
    if (pathname === "/") {
      // is home page
      // console.log("is home")
      openPageLink(siteTitleLink)
      // --------------------------------------------------------
    } else if (pathname.startsWith("/projects/")) {
      openProjectWithSlug(pathname)
    } else {
      // not home nor project
      // console.log("not home check menu items", $("li.menu-item").length)
      // first check if it's a menu item. exclude the lang menu item
      let found = false
      $("li.menu-item:not(.lang-item)").each(function () {
        if ($(this).children("a").attr("href").includes(pathname)) {
          found = true
          // console.log("menu link found", $(this).children("a").attr("href"))
          // if page
          if ($(this).hasClass("menu-item-object-page")) openPageLink($(this).children("a"))
          // if project
          else if ($(this).hasClass("menu-item-object-page")) openProjectWithSlug(pathname)
          return false
        }
      })
      if (!found) {
        // was not a menu link
        // console.log("not menu link")
        // console.log("url is something else")
        // something other -> get post type for path
        $.getJSON(`${localized.root_url}/wp-json/pathtopost/v1/get?path=${pathname}`, post => {
          // console.log(post)
          if (post.ID > 0) {
            // post found
            if (post.post_type == "page") {
              // page that's not in the menu
              openPageWithID(pageID)
              // // hide all other page and post items
              // $(".post-item-container").not(`#${pageID}`).hide()
              // $("li.menu-item").removeClass("current-menu-item")
              // $(`#${pageID}`).fadeIn()
            } else if (post.post_type == "post") {
              openPostWithID(post.ID)
            } else if (post.post_type == "project") {
              openProjectWithID(post.ID)
            } else {
              // hide all other post items
              $(".post-item-container").not(`#${post.ID}`).hide()
              $("li.menu-item").removeClass("current-menu-item")
              $(`#${post.ID}`).fadeIn()
            }
          } else {
            // is not a post
          }
        })
      }
    }
  }

  //
  function setCurrentContentID(id) {
    prevContentID = currentContentID
    currentContentID = id
    // console.log("current content:", currentContentID, prevContentID)
  }

  let resizeTimeout = null
  let resizeTimeout2 = null

  window.addEventListener("resize", () => {
    checkMobileView()

    clearTimeout(resizeTimeout)
    resizeTimeout = setTimeout(() => {
      // console.log("resize")
      touchSupport()
      onOrientationChange()
      setAdminBarHeight()
      calcVhPropertyFromClientHeight()
      navAndTitleHeight(true)
      checkMobileView()
      navAndTitleHeight(true)
    }, 500)

    clearTimeout(resizeTimeout2)
    resizeTimeout2 = setTimeout(() => {
      // for ios safari to get correct window height
      calcVhPropertyFromClientHeight()
    }, 1000)
  })

  function touchSupport() {
    const touchsupport = "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

    if (!touchsupport) {
      // browser doesn't support touch
      // document.documentElement.className += " non-touch"
      $("html").addClass("non-touch")
      $("html").removeClass("touch")
    } else {
      $("html").removeClass("non-touch")
      $("html").addClass("touch")
    }
  }

  function checkMobileView() {
    if ($(window).width() < mobileBreakpoint) {
      mobileView = true
      $("html").addClass("mobile-view")
      $("html").removeClass("desktop-view")
    } else {
      // if (mobileView) {
      //   // change from mobile to desktop
      //   openNav()
      // }
      mobileView = false
      $("html").removeClass("mobile-view")
      $("html").addClass("desktop-view")
    }
    // console.log("window width", $(window).width());
  }

  function onOrientationChange() {
    // detect orientation change
    if ((orientationLandscape && window.innerWidth < window.innerHeight) || (!orientationLandscape && window.innerWidth >= window.innerHeight)) {
      setLandscape()
    }
  }

  function setLandscape() {
    // store new orientation
    orientationLandscape = window.innerWidth >= window.innerHeight

    if (orientationLandscape) {
      $("html").addClass("orientation-landscape")
      $("html").removeClass("orientation-portrait")
    } else {
      $("html").removeClass("orientation-landscape")
      $("html").addClass("orientation-portrait")
    }
    // console.log("orientation changed, landscape:", orientationLandscape);
  }

  function setAdminBarHeight() {
    let wpabh = 0
    const adminBar = $("#wpadminbar")
    if (adminBar.length) {
      wpabh = adminBar.outerHeight()
    }
    adminBarHeight = wpabh
    $(":root").css("--adminBarHeight", `${adminBarHeight}px`)
  }

  function navAndTitleHeight(checkNav) {
    siteTitleHeight = siteTitle.outerHeight()
    $(":root").css("--siteTitleHeight", `${siteTitleHeight}px`)
    // console.log("title height", siteTitleHeight)
    titleHeight = titleContainer.outerHeight()
    $(":root").css("--titleHeight", `${titleHeight}px`)

    // get nav height only when nav closed
    if (!navOpen && checkNav) {
      navHeight = navContainer.outerHeight()
      $(":root").css("--navHeight", `${navHeight}px`)
    }
  }

  function calcVhPropertyFromClientHeight() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    // let vh = document.documentElement.clientHeight * 0.01
    const vh = window.innerHeight * 0.01
    // console.log("height: ", vh * 100);
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    // if (debug) console.log("client height: ", document.documentElement.clientHeight);

    //
    // const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    // const h = document.documentElement.clientHeight
    // console.log("ios vh:", vh * 100, "vh:", h)
  }

  // reload page on broswer back / forward
  window.onpopstate = function (event) {
    // console.log("on popstate", event);
    gotoLocation(window.location.pathname)
  }

  jQuery(document.body).on("post-load", function () {
    // New content has been added to the page.
    // console.log("post-load")
  })

  // for desktop: overflow auto for .content-container not body -> get scroll position from content-container
  document.querySelector(".content-container").addEventListener("scroll", function (e) {
    // console.log("scroll in content container", e.target.scrollTop)
    scrollYPositions[currentContentID] = e.target.scrollTop
  })

  document.addEventListener("scroll", function (e) {
    const currentScroll = window.pageYOffset
    // const currentScroll = window.scrollY;
    const scrollDelta = 0.5 * (prevScrollDelta + (currentScroll - lastScroll))

    // console.log("scroll: ", currentScroll)
    // console.log("scroll: ", currentScroll, window.scrollY, lastScroll, scrollDelta, prevScrollDelta)

    prevScrollDelta = scrollDelta

    // // store scroll y for page contents
    if (!navOpen) {
      scrollYPositions[currentContentID] = currentScroll
      // console.log(scrollYPositions)
    }

    // console.log(scrollYPositions);
    if (window.scrollY === 0) {
      // on top
      siteHeader.removeClass("site-header--visible") // -> sticky == -navheight
      navContainer.removeClass("site-menu--visible")
      // openNav();
    } else if (currentScroll > lastScroll && scrollDelta > 5 && !navChanging && !autoScrolling) {
      // reduce sensitivity
      // down
      siteHeader.removeClass("site-header--visible") // -> sticky == -navheight
      navContainer.removeClass("site-menu--visible")
      closeNav()
      // console.log("down - close nav")
    } else if (currentScroll < lastScroll && scrollDelta < -5 && window.scrollY > navHeight + siteTitleHeight && !navOpen && !autoScrolling) {
      // UP --- UP
      // openNav();
      siteHeader.addClass("site-header--visible") // sticky = adminbarheight
      navContainer.addClass("site-menu--visible")
      // console.log("up")
    }

    lastScroll = Math.max(0, currentScroll)
  })

  function checkFlexGap() {
    // create flex container with row-gap set
    var flex = document.createElement("div")
    flex.style.display = "flex"
    flex.style.flexDirection = "column"
    flex.style.rowGap = "1px"
    flex.style.position = "absolute"

    // create two, elements inside it
    flex.appendChild(document.createElement("div"))
    flex.appendChild(document.createElement("div"))

    // append to the DOM (needed to obtain scrollHeight)
    document.body.appendChild(flex)
    var isSupported = flex.scrollHeight === 1 // flex container should be 1px high from the row-gap
    flex.parentNode.removeChild(flex)

    return isSupported
  }

  const onresize = (dom_elem, callback) => {
    const resizeObserver = new ResizeObserver(() => callback())
    resizeObserver.observe(dom_elem)
  }

  let titleResizeTimeout = null
  onresize(siteTitle[0], function () {
    clearTimeout(titleResizeTimeout)
    titleResizeTimeout = setTimeout(function () {
      // console.log("title changed size")
      navAndTitleHeight(false)
    }, 50)
  })

  function observeElement($this) {
    if (observer) {
      observer.disconnect()
    } else {
      observer = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            // console.log("ENTER")
            arrowDownContainer.addClass("hidden")
          } else {
            // console.log("LEAVE")
            arrowDownContainer.removeClass("hidden")
          }
        },
        {
          root: null,
          threshold: 0.01 // set offset 0 means trigger if atleast 0% of element in viewport
        }
      )
    }
    observer.observe($this[0])
  }

  function stopVideo(el) {
    // console.log("func stop this, el", this, el.tagName)

    if (el?.tagName === "IFRAME") {
      // console.log("stop the iframe")
      // iframe of youtube player
      el.contentWindow.postMessage(
        JSON.stringify({
          event: "command",
          func: "pauseVideo"
        }),
        "*"
      )
      el.contentWindow.postMessage('{"method":"pause"}', "*")
    } else if (el?.tagName === "VIDEO") {
      // console.log("stop the video")
      // video element
      el.pause()
    }
  }

  function stopAllVideos() {
    // console.log("stop all videos")
    $(".swiper-slide").each(function () {
      const yt = this.querySelector("iframe")
      if (yt) stopVideo(yt)
      const video = this.querySelector("video")
      if (video) stopVideo(video)
    })
    // stop videos maybe in the content container
    const elements = contentContainer[0].querySelectorAll("iframe, video")
    elements.forEach(el => {
      // console.log("iframe or video found")
      stopVideo(el)
    })
  }
})
